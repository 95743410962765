<template>
  <div class="container">
    <mescroll-vue
      class="mescroll"
      id="container"
      ref="mescroll"
      :down="mescrollDown"
      :up="mescrollUp"
      @init="mescrollInit"
    >
      <div class="mescroll-view">
        <div
          class="mescroll-item"
          v-for="(item, index) in dataList"
          :key="index"
        >
          <div class="text">
            <div class="title">{{ set_walletAddressHide(item.address) }}</div>
            <div class="number">{{ item.marketamcc }}</div>
          </div>
          <div class="text2">
            <div class="date">{{ $t("common.address") }}</div>
            <div class="label">{{ $t("center.userStakingAmount") }}</div>
          </div>
        </div>
      </div>
    </mescroll-vue>
  </div>
</template>

<script>
import MescrollVue from "mescroll.js/mescroll.vue";
import { getInvitationList } from "@/request/api";
import { walletAddressHide } from "@/common/utils";
export default {
  components: { MescrollVue },
  data() {
    return {
      mescroll: null,
      mescrollDown: {
        textInOffset: this.$t("mescroll.textInOffset"),
        textOutOffset: this.$t("mescroll.textOutOffset"),
        textLoading: this.$t("mescroll.textLoading"),
      },
      mescrollUp: {
        callback: this.upCallback,
        empty: {
          tip: this.$t("mescroll.empty"),
          warpId: "container",
          icon: "/images/mescroll-empty.png",
        },
        htmlLoading: `<p class="upwarp-progress mescroll-rotate"></p><p class="upwarp-tip">${this.$t(
          "mescroll.loading"
        )}</p>`,
        htmlNodata: `<p class="upwarp-nodata">-- ${this.$t(
          "mescroll.end"
        )} --</p>`,
      },
      dataList: [], // 列表数据
      number: "",
    };
  },
  mounted() {},
  methods: {
    set_walletAddressHide(str) {
      return walletAddressHide(str);
    },
    mescrollInit(mescroll) {
      this.mescroll = mescroll;
    },
    upCallback(page, mescroll) {
      getInvitationList({ page: page.num, limit: page.size, type: 2 }).then(
        (res) => {
          if (res.code == 200) {
            // 请求的列表数据
            let arr = res.data.list;
            // 如果是第一页需手动置空列表
            if (page.num === 1) this.dataList = [];
            // 把请求到的数据添加到列表
            this.dataList = this.dataList.concat(arr);
            // 数据渲染成功后,隐藏下拉刷新的状态
            this.$nextTick(() => {
              mescroll.endBySize(arr.length, res.data.total);
            });
          } else {
            this.$toast.fail(res.msg);
            mescroll.endErr();
          }
        }
      );
    },
    routerPush(path) {
      this.$router.push({ path });
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  overflow: hidden;
  background-color: #fff !important;

  .mescroll {
    .mescroll-view {
      .mescroll-item {
        padding: 26px 34px;
        margin-bottom: 30px;
        background-color: rgba(255, 215, 156, 0.2);
        border-radius: 20px;

        .text,
        .text2 {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .text {
          font-size: 32px;
          font-weight: 500;
          color: #333333;
          margin-bottom: 25px;
        }

        .text2 {
          font-size: 24px;
          font-weight: 500;
          color: #999999;
        }
      }
    }
  }
}
</style>
